<template>
<div >
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>

  <transition name="fade">
    <div class="card" v-if="state.initialMyList.length > 0 && !state.loaderEnabled">
      <div class="card-header border-0 pt-6">

        <router-link to="/addupdateticket" class="link-primary fs-6 fw-bolder btn btn-primary mt-2" style="margin-top: 9px !important; height: 44px;">
          Créer un nouveau ticket
        </router-link>

        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Tickets"
          widthDecalage="250"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="  alert  bg-light-primary  alert-primary  d-flex  align-items-center  p-5  mb-10"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/general/gen048.svg" />
                  </span>
                  <div class="d-flex flex-column">
          <h4 class="mb-1 text-dark">Aucun Contrat</h4>
          <span>Il n'existe aucun ticket à afficher correspondant à vos critères de recherche.</span>
        </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucun ticket à afficher."
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-tic_seq="{ row: data }">
            <span class="fw-bolder text-gray-800 fs-6 m-titre" >Ticket #{{ data.tic_seq }}</span>
            <div class="text-muted fw-bold d-block mt-2 m-titre">{{ data.tic_c_label }}</div>
          </template>
          <template v-slot:cell-tic_d_creation="{ row: data }">
            <span class="text-primary fw-bolder py-1">{{formatDate(data.tic_d_creation)}}</span>
            <div class="text-muted d-block mt-2 m-titre">{{ data.con_prenom_nom }}</div>
          </template>
          <template v-slot:cell-tic_c_status="{ row: data }">
            <span
              style="cursor: default"
              :class="styleBtnStatus(data)"
              class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg :src="styleImgStatus(data)" />
              </span>
              {{ data.tic_c_status }}
            </span>


            
          </template>
          <template v-slot:cell-tic_knum_societe="{ row: data }">
           <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click='affDetail(data)'>
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                Afficher le ticket
            </a>
          </template>

          
        </Datatable>
      </div>
    </div>
  </transition>
  <el-drawer v-model="state.drawer" :with-header="false" size="90%" modal-class="modal-transp" v-show="state.ticketSelect.tic_seq">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> Retour</button>
          <h1 class="d-flex align-items-center text-dark my-1 fs-3">
            Détail du ticket #{{ state.valTicket.tic_seq }} : {{ state.valTicket.tic_c_status }}
          </h1>
        </div>
        <div class="card-toolbar">
          <button v-if="state.ticketSelect && state.ticketSelect.tic_seq && posIndex()>0" type="button" class="btn btn-sm btn-text-icon btn-active-light-primary me-n0" @click="affPrecedent()">
          <span class="svg-icon svg-icon-1">
            <inline-svg src="media/icons/duotune/arrows/arr021.svg" />
          </span>
          <span>Ticket précédent</span>
          </button>
          <button v-if="state.ticketSelect && state.ticketSelect.tic_seq && posIndex() < state.myList.length-1" type="button" class="btn btn-sm btn-text-icon btn-active-light-primary me-n5" @click="affSUivant()">
          <span>Ticket suivant</span>
          <span class="svg-icon svg-icon-1">
            <inline-svg src="media/icons/duotune/arrows/arr024.svg" />
          </span>
          </button>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/backg.jpg) no-repeat center center fixed;  background-size: cover;">
        <el-main class='pt-0' style="padding-right: 0 !important;">
          <el-container>
            <el-main class='pt-0' style='padding-left: 13px !important;'>
              <affTicket v-if="!state.loaderEnabled" :objTicket="state.valTicket" :key="state.valTicket" />
            </el-main>
            <el-aside width="350px" class="d-none d-xxl-block">
              <chat :deltaHauteur="state.hauteurChat" class="m-ticket" :style="`border-radius: 5px; cursor: default; position: fixed;right: 15px;width: 358px;top:${state.topChat}px`"  dis_type_entity="ticket"  :dis_knum_entity="state.selTicket" :key="state.selTicket || state.hauteurChat" v-if="state.selTicket > 0" />
            </el-aside>
          </el-container>

        </el-main>
          
      </div>
    </div>
  </el-drawer>
  </div>
  <div class="card" v-if="state.initialMyList.length == 0 && !state.loaderEnabled">
    <el-empty description="Vous n'avez créer aucun ticket pour le moment">
      <router-link to="/addupdateticket" class="link-primary fs-6 fw-bolder btn btn-primary mt-7 mx-7">
        Créer votre premier ticket
      </router-link>
    </el-empty>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      ticketSelect: {},
      valTicket: {},
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
    });
    const tableHeader = ref([
      {
        name: "Ticket",
        key: "tic_seq",
        sortable: true,
      },
      {
        name: "Création",
        key: "tic_d_creation",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tic_c_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "tic_knum_societe",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Statut",
        key: "tic_c_status",
        label: "tic_c_status",
      },
      {
        name: "Demandeur",
        key: "con_prenom_nom",
        label: "con_prenom_nom",
      },
    ]);

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Demandeur": "con_prenom_nom",
      "Statut": "tic_c_status",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const passLng = (d) => {
      state.myList.forEach(
        function(item: any){
          if (item.displayrow == 1) {
            item.displayrow = 2
          } else {
            item.displayrow = 1
          }
        }
      );
    }

    const changeLabel = async (cont) => {
      mAxiosApi
        .post("updateContLabel", cont)
        .then(({ data }) => {
          // console.log(data["results"]);
        })
        .catch((err) => {
          console.error(err);
        });
    };


    const affDetail = async (data) => {
      
      state.selTicket = data.tic_seq;

      let myList = await getAxios("/getTicket/" + data.tic_seq);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      state.drawer = true;
    };

    const affSUivant = async () => {
      if (posIndex()<state.myList.length) {
        state.ticketSelect = state.myList[posIndex()+1];
        // await affDetail(state.myList[posIndex()+1]);
      }
    };

    const affPrecedent = async () => {
      if (posIndex()>0) {
        state.ticketSelect = state.myList[posIndex()-1];
        // await affDetail(state.myList[posIndex()-1]);
      }
    };

    const posIndex = () => {
      const mlContrat: any = state.myList;
      const indexCont = mlContrat.findIndex(function (data: any) {
        const mContrat: any = state.ticketSelect;
        return data.fsh_contract_number == mContrat.fsh_contract_number;
      });
      return indexCont;
    };

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    const changeContrat = (ev) => {
      // console.log('Passe ici ', ev);

      const index = state.myList.findIndex(
        (object: any) => object.code === ev.code
      );
      const myList: any = state.myList;
      myList[index]['cont_x_archive'] = ev.cont_x_archive;
      // console.log('INDEX', index);

    }

    const styleImgStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };

    onMounted(async () => {
      MenuComponent.reinitialization();

      let myList = await getAxios("/getTickets");
      state.initialMyList = myList.results;
      state.myList = myList.results;      
      // console.log(state.myList);
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      imgConst,
      passLng,
      changeLabel,
      affSUivant,
      affPrecedent,
      posIndex,
      refreshRouter,
      changeContrat,
      styleImgStatus,
      styleBtnStatus
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>

<style>
.el-dropdown-menu__item:hover {
  cursor: pointer;
  background-color: #e4e7ed !important;
}
</style>